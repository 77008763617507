import React, { useState, useRef } from "react"
import styled from "styled-components"
import DesignTokens from "./designTokens"
import { motion } from "framer-motion"
import { Container, Row, Col } from "react-grid-system"
import Img from "gatsby-image"
import { useInView } from "react-intersection-observer"
import { Lead, DisplaySmall } from "./typography"
// import drag from "../images/drag.svg"

const FullGalleryBlock = ({ gallery }) => {
  const stageCanvasRef = useRef(null)
  const { ref, inView } = useInView({
    /* Optional options */
    threshold: 0.4,
  })

  return (
    <Wrapper ref={ref} inView={inView}>
      <div>
        <Container>
          <Row>
            <Col>
              <Legend>
                <DisplaySmall uppercase>Gallery</DisplaySmall>
              </Legend>
            </Col>
          </Row>
        </Container>
      </div>
      <ScrollWrapper>
        <Container>
          <Row>
            <Col xl={10} offset={{ xl: 2 }}>
              <GalleryWrapper ref={stageCanvasRef}>
                <Content drag="x" dragConstraints={stageCanvasRef}>
                  {gallery.map(image => (
                    <ImageWrapper key={image.originalId}>
                      {/* <Img fixed={image.fixed} draggable={false} /> */}
                      <img src={image.url} draggable={false} />
                    </ImageWrapper>
                  ))}
                </Content>
                {/* <Drag>
                <img src={drag} />
              </Drag> */}
              </GalleryWrapper>
            </Col>
          </Row>
        </Container>
      </ScrollWrapper>
    </Wrapper>
  )
}

const Legend = styled.div`
  padding-top: 3rem;
  padding-bottom: 1rem;
`

const Wrapper = styled.div`
  opacity: ${props => (props.inView ? "1" : "0")};
  background: white;
  margin-bottom: 3rem;
  transition: opacity 1s;
`

const ScrollWrapper = styled.div`
  overflow-y: scroll;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  &::-webkit-scrollbar {
    /* WebKit */
    width: 0;
    height: 0;
  }
`

const GalleryWrapper = styled.div`
  position: relative;
  height: 684px;
`

const Content = styled(motion.ul)`
  position: absolute;
  top: 0;
  list-style: none;
  left: 15px;
  display: flex;
  flex-direction: row;
  overflow-y: scroll;
  justify-content: flex-start;
  cursor: grab;
  & > * {
    margin-right: 2rem;
    &:last-child {
      margin-right: 0;
    }
  }
`

const ImageWrapper = styled.li`
  height: 684px;
  flex-grow: 1;
  width: max-content;
  img {
    max-height: 100%;
    min-width: 100%;
    height: 684px;
    object-fit: cover;
    vertical-align: bottom;
  }
`

const Drag = styled.div`
  position: absolute;
  bottom: -2rem;
  left: 1rem;
  width: 80;
  height: 80;
`

export default FullGalleryBlock
