import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import Layout from "../components/layout"
import TextBlock from "../components/textBlock"
import ValuesBlock from "../components/valuesBlock"
import ImageBlock from "../components/imageBlock"
import TeamBlock from "../components/teamBlock"
import HeroBlock from "../components/heroBlock"
import GalleryBlock from "../components/galleryBlock"
import NumbersBlock from "../components/numbersBlock"
import TravelJournalBlock from "../components/travelJournalBlock"
import FormBlock from "../components/formBlock"
import FullGalleryBlock from "../components/fullGalleryBlock"

const Page = ({ data: { page }, pageContext, location }) => {
  return (
    <Layout
      lang={pageContext.locale}
      title={page.title}
      location={location}
      allSlugLocales={page._allSlugLocales}
    >
      <Content>
        {page.content.map((section, index) => (
          <section key={section.id}>
            {section.model.apiKey === "hero_block" && (
              <HeroBlock
                title={section.title}
                text={section.text}
                page={page.slug}
                lang={pageContext.locale}
              />
            )}
            {section.model.apiKey === "numbers_block" && (
              <NumbersBlock numbers={section.number} images={section.images} />
            )}
            {section.model.apiKey === "text_block" && (
              <TextBlock
                title={section.title}
                subtitle={section.subtitle}
                text={section.text}
                border={section.border}
              />
            )}
            {section.model.apiKey === "form_block" && (
              <FormBlock title={section.title} subtitle={section.subtitle} />
            )}
            {section.model.apiKey === "gallery_block" && (
              <GalleryBlock
                title={section.title}
                text={section.text}
                gallery={section.gallery}
                parentIndex={index}
              />
            )}
            {section.model.apiKey === "values_block" && (
              <ValuesBlock values={section.values} />
            )}
            {section.model.apiKey === "travel_journal_block" && (
              <TravelJournalBlock cities={section.cities} />
            )}
            {section.model.apiKey === "image_block" && (
              <ImageBlock image={section.image} fullWidth={section.fullWidth} />
            )}
            {section.model.apiKey === "team_block" && (
              <TeamBlock people={section.people} />
            )}
            {section.model.apiKey === "full_gallery_block" && (
              <FullGalleryBlock gallery={section.images} />
            )}
          </section>
        ))}
      </Content>
    </Layout>
  )
}

const Content = styled.div``

export default Page

export const query = graphql`
  query PageQuery($slug: String!, $locale: String!) {
    page: datoCmsPage(slug: { eq: $slug }, locale: { eq: $locale }) {
      _allSlugLocales {
        locale
        value
      }
      slug
      title
      id
      locale
      content {
        ... on DatoCmsFullGalleryBlock {
          images {
            fixed(width: 480, imgixParams: { fm: "jpg" }) {
              ...GatsbyDatoCmsFixed
            }
            url
            originalId
          }
          id
          model {
            apiKey
          }
        }
        ... on DatoCmsTravelJournalBlock {
          cities {
            id
            location
            coordinates {
              latitude
              longitude
            }
            text
            subtitle
            image {
              fluid(maxWidth: 768, imgixParams: { fm: "jpg" }) {
                ...GatsbyDatoCmsFluid
              }
            }
          }
          id
          model {
            apiKey
          }
        }
        ... on DatoCmsTextBlock {
          title
          subtitle
          text
          border
          id
          model {
            apiKey
          }
        }
        ... on DatoCmsFormBlock {
          title
          subtitle
          id
          model {
            apiKey
          }
        }
        ... on DatoCmsValuesBlock {
          values {
            id
            text
            name
            image {
              fluid(maxWidth: 768, imgixParams: { fm: "jpg" }) {
                ...GatsbyDatoCmsFluid
              }
            }
          }
          id
          model {
            apiKey
          }
        }
        ... on DatoCmsImageBlock {
          image {
            fluid(maxWidth: 1440, imgixParams: { fm: "jpg" }) {
              ...GatsbyDatoCmsFluid
            }
          }
          id
          fullWidth
          model {
            apiKey
          }
        }
        ... on DatoCmsTeamBlock {
          people {
            id
            name
            quote
            role
            since
            text
            image {
              fluid(maxWidth: 768, imgixParams: { fm: "jpg" }) {
                ...GatsbyDatoCmsFluid
              }
            }
          }
          id
          model {
            apiKey
          }
        }
        ... on DatoCmsNumbersBlock {
          number {
            name
            number
            id
          }
          images {
            fluid(maxWidth: 1440, imgixParams: { fm: "jpg" }) {
              ...GatsbyDatoCmsFluid
            }
            originalId
          }
          id
          model {
            apiKey
          }
        }
        ... on DatoCmsGalleryBlock {
          title
          text
          gallery {
            fluid(maxWidth: 1440, imgixParams: { fm: "jpg" }) {
              ...GatsbyDatoCmsFluid
            }
            originalId
          }
          id
          model {
            apiKey
          }
        }
        ... on DatoCmsHeroBlock {
          title
          text
          id
          model {
            apiKey
          }
        }
      }
    }
  }
`
