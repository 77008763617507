import React from "react"
import styled from "styled-components"
import DesignTokens from "./designTokens"
import { Container, Row, Col } from "react-grid-system"
import ContactForm from "./contactForm"
import { HeadingSmall, Caption } from "./typography"

const FormBlock = ({ title, subtitle }) => (
  <Wrapper>
    <Container>
      <Row>
        <Col md={4} xl={2}>
          <Title>
            <Caption>{title}</Caption>
            <HeadingSmall>{subtitle}</HeadingSmall>
          </Title>
        </Col>
        <Col md={6} xl={6} offset={{ xl: 1 }} >
            <ContactForm />
        </Col>
      </Row>
    </Container>
  </Wrapper>
)

const Wrapper = styled.div`
  padding-top: 3rem;
  padding-bottom: 3rem;
`

const Title = styled.div`
  & > * {
    margin-bottom: 1rem;
    &:last-child {
      margin-bottom: 0;
    }
  }
`

export default FormBlock
