import React, { useState } from "react"
import styled from "styled-components"
import DesignTokens from "./designTokens"
import Img from "gatsby-image"
import RichContentTextBlock from "./richContentTextBlock"
import { MapContainer, TileLayer, Marker } from "react-leaflet"
import L from "leaflet"
import { HeadingSmall } from "./typography"
import { useReference } from "../hooks/useReference"
import MapPin from "../images/map-pin.svg"
import MapPinActive from "../images/map-pin.svg"
import MapSmall from "../images/map-pin-small.svg"

function LocationMarker({ city, setCity, currentCity }) {
  const icon = new L.Icon({
    iconUrl: MapPin,
    iconRetinaUrl: MapPin,
    iconAnchor: null,
    popupAnchor: null,
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null,
    iconSize: new L.Point(40, 40),
  })

  const activeIcon = new L.Icon({
    iconUrl: MapPinActive,
    iconRetinaUrl: MapPinActive,
    iconAnchor: null,
    popupAnchor: null,
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null,
    iconSize: new L.Point(40, 40),
  })

  return (
    <Marker
      position={[city.coordinates.latitude, city.coordinates.longitude]}
      icon={currentCity.id === city.id ? activeIcon : icon}
      key={city.id}
      eventHandlers={{
        click: e => {
          setCity(city)
        },
      }}
    />
  )
}

function OtherMarker({ city }) {
  const smallIcon = new L.Icon({
    iconUrl: MapSmall,
    iconRetinaUrl: MapSmall,
    iconAnchor: null,
    popupAnchor: null,
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null,
    iconSize: new L.Point(17, 17),
  })

  return (
    <Marker
      position={[city.coordinates.latitude, city.coordinates.longitude]}
      icon={smallIcon}
      key={city.id}
    />
  )
}

const TravelJournalBlock = ({ cities }) => {
  const otherCities = useReference()
  const otherCitiesI18n = otherCities.filter(obj => {
    return obj.locale === "it"
  })

  const [currentCity, setCity] = useState(cities[0])
  const locationBounds = cities.map(city => [
    city.coordinates.latitude,
    city.coordinates.longitude,
  ])
  const otherCitiesBounds = otherCitiesI18n.map(city => [
    city.coordinates.latitude,
    city.coordinates.longitude,
  ])
  const bounds = locationBounds.concat(otherCitiesBounds)

  return (
    <Wrapper>
      <Journal>
        <Img fluid={currentCity.image.fluid} />
        <Details>
          <City>{currentCity.location}</City>
          <HeadingSmall>{currentCity.subtitle}</HeadingSmall>
          <RichContentTextBlock html={currentCity.text} />
        </Details>
      </Journal>
      {typeof window !== "undefined" && (
        <Map scrollWheelZoom={false} bounds={bounds}>
          <TileLayer url="https://api.mapbox.com/styles/v1/multiconsult/clypfqqj5006t01pihn6ha24e/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoibXVsdGljb25zdWx0IiwiYSI6ImNseXBmZWtkOTB0YXQya3F5M3c1cnhrOTQifQ.HHSRcmfAQaP472CEhniP7Q" />
          {otherCitiesI18n.map(city => (
            <OtherMarker city={city} key={city.id} />
          ))}
          {cities.map(city => (
            <LocationMarker
              city={city}
              key={city.id}
              setCity={setCity}
              currentCity={currentCity}
            />
          ))}
        </Map>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  @media screen and (max-width: 425px) {
    flex-direction: column;
  }
`

const Journal = styled.div`
  width: 30rem;
  @media screen and (max-width: 425px) {
    width: 100%;
  }
`

const City = styled.div`
  text-transform: uppercase;
  font-size: ${DesignTokens.fontSizes[3]};
  text-decoration: underline;
`

const Details = styled.div`
  padding: 3rem;
  & > * {
    margin-bottom: 1rem;
    &:last-child {
      margin-bottom: 0;
    }
  }
`

const Map = styled(MapContainer)`
  width: 100%;
  height: 80vh;
  @media screen and (max-width: 425px) {
    height: 40vh;
  }
`

export default TravelJournalBlock
