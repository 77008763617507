import { useStaticQuery, graphql } from "gatsby"

export const useReference = () => {
  const menu = useStaticQuery(graphql`
    query ReferenceQuery {
      allDatoCmsReference {
        nodes {
          location
          id
          locale
          coordinates {
            latitude
            longitude
          }
        }
      }
    }
  `)

  return menu.allDatoCmsReference.nodes
}
