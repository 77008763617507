import React, { useState } from "react"
import axios from "axios"
import styled from "styled-components"
import DesignTokens from "./designTokens"
import { i18nContext } from "./i18nContext"

const ContactForm = () => {
  const [email, setEmail] = useState("")
  const [name, setName] = useState("")
  const [company, setCompany] = useState("")
  const [phone, setPhone] = useState("")
  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(false)
  const [body, setBody] = useState("")

  const handleSubmit = async event => {
    event.preventDefault()
    setLoading(true)

    const data = {
      name: name,
      email: email,
      company: company,
      phone: phone,
      body: body,
      subscribedAt: new Date().toISOString(),
    }

    try {
      await axios.post("/.netlify/functions/sendMail", data)
      if (typeof window !== "undefined" && window.dataLayer !== undefined) {
        window.dataLayer = window.dataLayer || []
        window.dataLayer.push({
          event: "formSubmission",
          formType: "Contact",
        })
      }
      setSuccess(true)
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  return (
    <i18nContext.Consumer>
      {t => (
        <Wrapper id="form">
          <form method="post" name="download" onSubmit={handleSubmit}>
            <section>
              <label htmlFor="name"> {t.formInputTexts.fullName}</label>
              <input
                onChange={e => setName(e.target.value)}
                type="text"
                name="name"
                id="name"
                value={name}
                required
              />
            </section>
            <section>
              <label htmlFor="phone">{t.formInputTexts.phone}</label>
              <input
                onChange={e => setPhone(e.target.value)}
                type="phone"
                name="phone"
                id="phone"
                value={phone}
                required
              />
            </section>
            <section>
              <label htmlFor="email">Email *</label>
              <input
                onChange={e => setEmail(e.target.value)}
                type="email"
                name="email"
                id="email"
                value={email}
                required
              />
            </section>
            <section>
              <label htmlFor="company">{t.formInputTexts.company}</label>
              <input
                onChange={e => setCompany(e.target.value)}
                type="text"
                name="company"
                id="company"
                value={company}
              />
            </section>
            <section>
              <label htmlFor="body">{t.formInputTexts.message}</label>
              <textarea
                onChange={e => setBody(e.target.value)}
                type="body"
                name="body"
                id="body"
                value={body}
              />
            </section>
            <section>
              <p>{t.formInputTexts.privacyPolicy}</p>
            </section>
            <Checkbox>
              <input type="checkbox" id="privacy" name="privacy" required />
              <label htmlFor="privacy">{t.formInputTexts.button}</label>
            </Checkbox>
            <section>
              {!success && (
                <input
                  type="submit"
                  value={loading ? `Caricamento...` : t.formInputTexts.send}
                />
              )}
              {success && (
                <input type="submit" value={t.formInputTexts.sent} disabled />
              )}
            </section>
          </form>
        </Wrapper>
      )}
    </i18nContext.Consumer>
  )
}

export default ContactForm

const InputGroup = styled.div`
  display: flex;
  flex-direction: row;
  div {
    margin: 0 ${DesignTokens.spacing[2]};
    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }
  }
`

const Checkbox = styled.div`
  display: flex;
  flex-direction: row;
  input {
    margin-right: 0.5rem;
  }
  label {
    font-size: 14px;
    line-height: 1.1;
    margin-bottom: 0 !important;
  }
`

const RadioButton = styled.div`
  display: flex;
  flex-direction: row;
  input {
    margin-right: 0.5rem;
  }
  label {
    font-size: 14px;
    line-height: 1.1;
    margin-bottom: 0 !important;
  }
`

const Wrapper = styled.div`
  /* padding: 2rem; */
  border-radius: ${DesignTokens.borderRadius};
  margin-bottom: 6rem;
  form {
    padding-top: 2rem;
    label {
      margin-bottom: 0.25rem;
      color: black;
      font-size: 14px;
    }
    input,
    textarea {
      padding: ${DesignTokens.spacing[3]};
      color: black;
      border: none;
      font-size: 16px;
      background: transparent;
      border-bottom: 1px solid ${DesignTokens.colors.primary[400]};
      &:hover,
      &:focus {
        border-bottom: 1px solid ${DesignTokens.colors.secondary[500]};
      }
    }

    input[type="submit"] {
      background: black;
      border: none;
      color: white;
      text-transform: uppercase;
      font-weight: bold;
      padding-top: 1.5rem;
      padding-bottom: 1.5rem;
      cursor: pointer;
      &:hover,
      &:active {
        background: ${DesignTokens.colors.primary[400]};
      }
      &:disabled {
        background: #4caf50;
        cursor: default;
      }
    }
    section {
      display: flex;
      flex-direction: column;
      p {
        font-size: 12px;
        color: ${DesignTokens.colors.primary[200]};
      }
    }
    & > * {
      margin-bottom: ${DesignTokens.spacing[4]};
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
`
